import { Action } from 'redux';

import { ISignInRequest, ISignInSuccess } from './types';

export const Actions = {
  SIGN_IN_REQUEST: '@auth/SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: '@auth/SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: '@auth/SIGN_IN_FAILURE',

  REHYDRATE: 'persist/REHYDRATE',
};

/*
  ! Login do usuário
*/
export function signInRequest(data: ISignInRequest): Action {
  return {
    type: Actions.SIGN_IN_REQUEST,
    payload: data,
  };
}

export function signInSuccess(data: ISignInSuccess): Action {
  return {
    type: Actions.SIGN_IN_SUCCESS,
    payload: data,
  };
}

export function signInFailure(): Action {
  return {
    type: Actions.SIGN_IN_FAILURE,
  };
}
