import React from 'react';

import { Grid } from '@chakra-ui/react';

import Drawer from '~/components/Drawer';
import Header from '~/components/Header';
import Modal from '~/components/Modal';
import SideMenu from '~/components/SideMenu';

import { Content } from './styles';

const Dashboard: React.FC = ({ children }) => {
  document.documentElement.style.setProperty('--background-color', '#eceff1');
  document.documentElement.style.setProperty('--font-color', '#3c3c3c');

  return (
    <Grid
      width="100%"
      minHeight="100vh"
      templateRows="70px 1fr"
      templateColumns="240px 1fr"
      templateAreas={`'header header header' 'nav main main'`}
    >
      <Header />

      <SideMenu />

      <Content speed={0.8} contentClassName="main-content" horizontal={false}>
        {children}
      </Content>
      <Modal />
      <Drawer />
    </Grid>
  );
};

export default Dashboard;
