import React from 'react';

import {
  AlertDialog as Dialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

interface AlertDialogProps {
  open: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
}
const AlertDialog: React.FC<AlertDialogProps> = ({
  onCancel,
  onConfirm,
  open,
  title,
}) => {
  const cancelRef = React.useRef(null);

  return (
    <Dialog isOpen={open} leastDestructiveRef={cancelRef} onClose={onCancel}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            Tem certeza? Você não pode desfazer esta ação depois.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCancel}>
              Foi um erro
            </Button>
            <Button colorScheme="red" onClick={onConfirm} ml={3}>
              Sim, Delete.
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </Dialog>
  );
};

export default AlertDialog;
