import { Reducer } from 'redux';

import produce from 'immer';

import { Actions } from './actions';
import { ISignReducer } from './types';

const INITIAL_STATE: ISignReducer = {
  loading: false,
  signed: false,
  token: null,
};

type AuthReducer = Reducer<ISignReducer>;

const auth: AuthReducer = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Actions.SIGN_IN_REQUEST: {
        draft.loading = true;

        break;
      }

      case Actions.SIGN_IN_SUCCESS: {
        draft.loading = false;
        draft.signed = true;
        draft.token = action.payload.token;

        break;
      }

      case Actions.SIGN_IN_FAILURE: {
        draft.loading = false;

        break;
      }

      default:
    }
  });
};

export default auth;
