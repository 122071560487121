import { all, call, put, takeLatest, Payload } from 'redux-saga/effects';

import { ApiResponse } from 'apisauce';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { setLoading, openModal } from '~/store/modules/global/actions';

import {
  Actions,
  deleteSellersFailure,
  deleteSellersSuccess,
  getSellersRequest,
  getSellersFailure,
  getSellersSuccess,
  createSellersFailure,
  createSellersSuccess,
  updateSellersFailure,
  updateSellersSuccess,
} from './actions';
import { SellerRequest, SellerData, FormData } from './types';

//! Busca os vendedoras
type GetSellerPayload = Payload<SellerRequest>;
export function* getVendedoras({ payload }: GetSellerPayload): Generator {
  const { filters } = payload;

  const response = yield call(() => {
    return api.get('sellers', filters);
  });

  const { data, ok } = response as ApiResponse<SellerData[]>;

  if (!ok) {
    toast.error('Erro ao buscar vendedoras cadastradas!');

    return yield put(getSellersFailure());
  }

  return yield put(getSellersSuccess(data));
}

//! Cria uma vendedora
type CreateSellerPayload = Payload<FormData>;
export function* createVendedora({ payload }: CreateSellerPayload): Generator {
  yield put(setLoading(true));

  const response = yield call(() => {
    return api.post('users', {
      ...payload,
      admin: false,
    });
  });

  const { ok } = response as ApiResponse<SellerData>;

  yield put(setLoading(false));
  if (!ok) {
    toast.error('Erro ao cadastrar vendedora!');

    return yield put(createSellersFailure());
  }

  yield put(openModal({ content: null, open: false, title: '' }));

  yield put(getSellersRequest({}));

  return yield put(createSellersSuccess());
}

//! Edita uma vendedora
type UpdateSellerPayload = Payload<SellerData>;
export function* editVendedora({ payload }: UpdateSellerPayload): Generator {
  yield put(setLoading(true));

  const { id } = payload;

  const response = yield call(() => {
    return api.put(`users/${id}`, payload);
  });

  const { ok } = response as ApiResponse<SellerData>;

  yield put(setLoading(false));

  if (!ok) {
    toast.error('Erro ao editar vendedora!');

    return yield put(updateSellersFailure());
  }

  yield put(openModal({ content: null, open: false, title: '' }));

  yield put(getSellersRequest({}));

  return yield put(updateSellersSuccess());
}

// !Deleta uma vendedora
type DelSellerPayload = Payload<{ id: number }>;
export function* deleteVendedora({ payload }: DelSellerPayload): Generator {
  const { id } = payload;

  const response = yield call(() => {
    return api.delete(`users/${id}`);
  });

  const { ok } = response as ApiResponse<ClientData[]>;

  if (!ok) {
    toast.error('Erro ao excluir vendedora!');

    return yield put(deleteSellersFailure());
  }
  toast.success('Vendedora excluído com sucesso!');

  yield put(getSellersRequest({}));

  return yield put(deleteSellersSuccess());
}

export default all([
  takeLatest(Actions.GET_SELLERS_REQUEST, getVendedoras),
  takeLatest(Actions.CREATE_SELLERS_REQUEST, createVendedora),
  takeLatest(Actions.UPDATE_SELLERS_REQUEST, editVendedora),
  takeLatest(Actions.DELETE_SELLERS_REQUEST, deleteVendedora),
]);
