import React from 'react';

import { useSelector } from 'react-redux';

import {
  Table as TableChakra,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Box,
  Badge,
} from '@chakra-ui/react';
import { NotePencil, Trash } from 'phosphor-react';

import Loading from '~/components/Loading';

import { ClientData } from '../../context/types';

interface TableProps {
  onDelete: (id: string) => void;
  onEdit: (data: ClientData) => void;
  onDetail: (data: ClientData) => void;
}

const Table: React.FC<TableProps> = ({ onDelete, onEdit, onDetail }) => {
  const clients = useSelector((state: State) => state.clients);

  return (
    <>
      <Loading loading={clients.loading} />
      <TableChakra variant="simple">
        <TableCaption>Imperial to metric conversion factors</TableCaption>
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>E-mail</Th>
            <Th>CPF</Th>
            <Th isNumeric>Telefone</Th>
            <Th>Status</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          {clients?.data?.map(client => (
            <Tr key={Math.random()} _hover={{ backgroundColor: '#f8fdff' }}>
              <Td cursor="pointer" onClick={() => onDetail(client)}>
                {client.name}
              </Td>
              <Td>{client.email}</Td>
              <Td>{client.document}</Td>
              <Td isNumeric>{client.cellphone || client.telephone}</Td>

              <Td>
                <Badge colorScheme={client.status?.type}>
                  {client.status?.name}
                </Badge>
              </Td>
              <Td isNumeric>
                <Button
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => onEdit(client)}
                >
                  <Box as={NotePencil} color="teal" size={20} />
                </Button>
                <Button
                  colorScheme="red"
                  variant="ghost"
                  onClick={() => onDelete(client.id)}
                >
                  <Box as={Trash} color="red" size={20} />
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </TableChakra>
    </>
  );
};

export default Table;
