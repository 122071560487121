import { all, call, put, takeLatest, Payload } from 'redux-saga/effects';

import { ApiResponse } from 'apisauce';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { setLoading, openModal } from '~/store/modules/global/actions';

import {
  Actions,
  deleteEventsFailure,
  deleteEventsSuccess,
  getEventsRequest,
  getEventsFailure,
  getEventsSuccess,
  createEventsFailure,
  createEventsSuccess,
  updateEventsFailure,
  updateEventsSuccess,
} from './actions';
import { EventRequest, EventData, FormData } from './types';

//! Busca os eventos
type GetEventPayload = Payload<EventRequest>;
export function* getEvents({ payload }: GetEventPayload): Generator {
  const { filters } = payload;

  const response = yield call(() => {
    return api.get('events', filters);
  });

  const { data, ok } = response as ApiResponse<EventData[]>;

  if (!ok) {
    toast.error('Erro ao buscar eventos cadastrados!');

    return yield put(getEventsFailure());
  }

  return yield put(getEventsSuccess(data));
}

//! Cria um  evento
type CreateEventPayload = Payload<FormData>;
export function* createEvent({ payload }: CreateEventPayload): Generator {
  yield put(setLoading(true));

  const response = yield call(() => {
    return api.post('events', payload);
  });

  const { ok } = response as ApiResponse<EventData>;

  yield put(setLoading(false));
  if (!ok) {
    toast.error('Erro ao cadastrar evento!');

    return yield put(createEventsFailure());
  }

  yield put(openModal({ content: null, open: false, title: '' }));

  yield put(getEventsRequest({}));

  return yield put(createEventsSuccess());
}

//! Edita um  evento
type UpdateEventPayload = Payload<EventData>;
export function* editEvent({ payload }: UpdateEventPayload): Generator {
  yield put(setLoading(true));

  const { id } = payload;

  const response = yield call(() => {
    return api.put(`events/${id}`, payload);
  });

  const { ok } = response as ApiResponse<EventData>;

  yield put(setLoading(false));
  if (!ok) {
    toast.error('Erro ao editar evento!');

    return yield put(updateEventsFailure());
  }

  yield put(openModal({ content: null, open: false, title: '' }));

  yield put(getEventsRequest({}));

  return yield put(updateEventsSuccess());
}

// !Deleta um cliente
type DelEventPayload = Payload<{ id: number }>;
export function* deleteEvent({ payload }: DelEventPayload): Generator {
  const { id } = payload;

  const response = yield call(() => {
    return api.delete(`events/${id}`);
  });

  const { ok } = response as ApiResponse<ClientData[]>;

  if (!ok) {
    toast.error('Erro ao excluir evento!');

    return yield put(deleteEventsFailure());
  }
  toast.success('Evento excluído com sucesso!');

  yield put(getEventsRequest({}));

  return yield put(deleteEventsSuccess());
}

export default all([
  takeLatest(Actions.GET_EVENTS_REQUEST, getEvents),
  takeLatest(Actions.CREATE_EVENTS_REQUEST, createEvent),
  takeLatest(Actions.UPDATE_EVENTS_REQUEST, editEvent),
  takeLatest(Actions.DELETE_EVENTS_REQUEST, deleteEvent),
]);
