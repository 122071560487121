import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { Box, Input, Button } from '@chakra-ui/react';

import { openModal } from '~/store/modules/global/actions';

import { useClient } from '../../context';
import { FormData } from '../../context/types';
import ModalContent from '../ModalContent';

interface FiltersProps {
  handleSubmitData: (data: FormData) => void;
}

const Filters: React.FC<FiltersProps> = ({ handleSubmitData }) => {
  const dispatch = useDispatch();

  const { filters, setFilters } = useClient();

  const handleUserCreate = useCallback(() => {
    dispatch(
      openModal({
        open: true,
        title: 'Adicionar cliente',
        content: <ModalContent type="new" handleSendData={handleSubmitData} />,
      }),
    );
  }, [dispatch, handleSubmitData]);

  return (
    <Box d="flex" justifyContent="flex-start" alignItems="center">
      <Input
        m="2"
        ml="0"
        maxW="330px"
        value={filters.name}
        onChange={e => setFilters({ name: e.target.value })}
        variant="outline"
        placeholder="Pesquise por nome"
        borderColor="rgba(128, 128, 128, 0.5)"
        h="50px"
        _hover={{
          borderColor: 'orange.500',
        }}
        _focus={{
          borderColor: 'orange.500',
        }}
      />
      <Input
        m="2"
        maxW="330px"
        variant="outline"
        borderColor="rgba(128, 128, 128, 0.5)"
        placeholder="Pesquise por CPF"
        h="50px"
        _hover={{
          borderColor: 'orange.500',
        }}
        _focus={{
          borderColor: 'orange.500',
        }}
      />
      <Input
        m="2"
        maxW="330px"
        variant="outline"
        borderColor="rgba(128, 128, 128, 0.5)"
        placeholder="Pesquise por data"
        h="50px"
        _hover={{
          borderColor: 'orange.500',
        }}
        _focus={{
          borderColor: 'orange.500',
        }}
      />

      <Button
        h="50px"
        w="100%"
        maxW="220px"
        colorScheme="orange"
        ml="auto"
        onClick={handleUserCreate}
      >
        Adicionar
      </Button>
    </Box>
  );
};

export default Filters;
