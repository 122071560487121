import { Reducer } from 'redux';

import produce from 'immer';

import { Actions } from './actions';
import { IEventReducer } from './types';

const INITIAL_STATE: IEventReducer = {
  loading: false,
  loadingDetail: false,
};

type Event = Reducer<IEventReducer>;

const events: Event = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Actions.GET_EVENTS_REQUEST: {
        draft.loading = true;
        break;
      }

      case Actions.GET_EVENTS_SUCCESS: {
        draft.loading = false;
        draft.data = action.payload;
        break;
      }

      case Actions.GET_EVENTS_FAILURE: {
        draft.loading = false;
        break;
      }

      case Actions.CREATE_EVENTS_REQUEST: {
        draft.loadingDetail = true;
        break;
      }

      case Actions.CREATE_EVENTS_SUCCESS: {
        draft.loadingDetail = false;
        break;
      }

      case Actions.CREATE_EVENTS_FAILURE: {
        draft.loadingDetail = false;
        break;
      }

      case Actions.DELETE_EVENTS_REQUEST: {
        draft.loading = true;
        break;
      }

      case Actions.DELETE_EVENTS_SUCCESS: {
        draft.loading = false;
        break;
      }

      case Actions.DELETE_EVENTS_FAILURE: {
        draft.loading = false;
        break;
      }

      case Actions.UPDATE_EVENTS_REQUEST: {
        draft.loadingDetail = true;
        break;
      }

      case Actions.UPDATE_EVENTS_SUCCESS: {
        draft.loadingDetail = false;
        break;
      }

      case Actions.UPDATE_EVENTS_FAILURE: {
        draft.loadingDetail = false;
        break;
      }

      default:
    }
  });
};

export default events;
