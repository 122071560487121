import React from 'react';

import logo from '~/assets/logo.png';

import { Container, Content, Background } from './styles';

const Auth: React.FC = ({ children }) => {
  return (
    <Container>
      <Content>
        <img src={logo} alt="Dress me up" />

        {children}
      </Content>
      <Background />
    </Container>
  );
};

export default Auth;
