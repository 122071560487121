import React, { useCallback, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Box, Button } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { User, Lock } from 'phosphor-react';
import { ValidationError } from 'yup';

import { Input } from '~/components/Form';

import { signInRequest } from '~/store/modules/auth/actions';
import getValidationErrors from '~/utils/getValidationErrors';
import signInValidator from '~/validators/auth/signIn';

const SignIn: React.FC = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state: State) => state.auth);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async data => {
      formRef.current?.setErrors({});

      try {
        await signInValidator.validate(data, {
          abortEarly: false,
        });

        dispatch(
          signInRequest({
            email: data.email,
            password: data.password,
          }),
        );
      } catch (error) {
        if (error as ValidationError) {
          const getErrors = getValidationErrors(error);

          formRef.current?.setErrors(getErrors);
        }
      }
    },
    [dispatch],
  );

  return (
    <Box>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          name="email"
          placeholder="Digite seu e-mail"
          backgroundColor="#232129"
          color="white"
          mb={4}
          iconLeft={<Box as={User} size={30} color="orange.500" />}
        />
        <Input
          name="password"
          placeholder="Digite sua senha"
          type="password"
          backgroundColor="#232129"
          color="white"
          iconLeft={<Box as={Lock} size={30} color="orange.500" />}
        />

        <Button
          type="submit"
          mt="5"
          colorScheme="orange"
          p="2"
          w="100%"
          h="3rem"
          isLoading={loading}
        >
          Entrar
        </Button>
      </Form>
    </Box>
  );
};

export default SignIn;
