import styled from 'styled-components';

export const Container = styled.nav`
  position: fixed;
  margin-top: 70px;
  width: 100%;
  max-width: 240px;
  height: 100%;
  grid-area: header;
  grid-area: nav;
  background: ${({ theme }) => theme.colors.blue[900]};
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  > a {
    width: 100%;
    min-height: 40px;
    padding: 10px 20px;
    color: white;
    background: none;
    border: none;
    outline: 0;
    text-align: left;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    > svg {
      margin-right: 10px;
    }
    &:before {
      content: '';
      height: 100%;
      width: 3px;
      background: ${({ theme }) => theme.colors.orange[500]};
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.2s linear;
      transform: translateX(-5px);
    }
    &:hover,
    &.active {
      &:before {
        transform: translateX(0px);
      }
    }
  }
`;
