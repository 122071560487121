import ScrollArea from 'react-scrollbar';
import styled from 'styled-components';

export const Content = styled(ScrollArea)`
  width: 100%;
  height: 100%;
  grid-area: header;
  grid-area: main;
  background-color: transparent;

  .main-content {
    padding: 30px 22px;
  }
`;
