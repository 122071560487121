import { Reducer } from 'redux';

import produce from 'immer';

import { Actions } from './actions';
import { IUserReducer } from './types';

const INITIAL_STATE: IUserReducer = {
  data: undefined,
};

type AuthReducer = Reducer<IUserReducer>;

const user: AuthReducer = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Actions.SIGN_IN_SUCCESS: {
        draft.data = action.payload.user;
        break;
      }

      default:
    }
  });
};

export default user;
