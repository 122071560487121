import React, { useCallback, useRef, useEffect } from 'react';

import { Stack, SimpleGrid, Box, Button } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { ValidationError } from 'yup';

import { Input } from '~/components/Form';

import getValidationErrors from '~/utils/getValidationErrors';
import clientValidator from '~/validators/clients';

import { useClient } from '../../context';
import { FormData } from '../../context/types';

interface ModalProps {
  type: 'new' | 'edit';
  handleSendData: (data: FormData) => void;
}

const Modal: React.FC<ModalProps> = ({ handleSendData, type }) => {
  const formRef = useRef<FormHandles>(null);

  const { formData, setFormData } = useClient();

  useEffect(() => {
    if (type === 'new') return;

    formRef?.current?.setData(formData);
  }, [formData, setFormData, type]);

  const handleSubmit = useCallback(
    async data => {
      formRef?.current?.setErrors({});

      try {
        await clientValidator.validate(data, {
          abortEarly: false,
        });

        handleSendData(data);
      } catch (errors) {
        if (errors instanceof ValidationError) {
          const sanitizeErrors = getValidationErrors(errors);

          formRef?.current?.setErrors(sanitizeErrors);
        }
      }
    },
    [handleSendData],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {type === 'edit' && <Input name="id" type="hidden" />}
        <Input name="name" placeholder="Nome completo" />
        <Input name="document" placeholder="CPF" />
        <Input name="email" placeholder="E-mail" />
        <SimpleGrid columns={2} spacing={2}>
          <Input name="cellphone" placeholder="Celular" />
          <Input name="telephone" placeholder="Telefone" />
        </SimpleGrid>
      </Stack>

      <Box w="50%" ml="auto" mt={5} mb={5}>
        <Button type="submit" float="right" mb="3" colorScheme="orange">
          {type === 'new' ? 'Cadastrar' : 'Editar'}
        </Button>
      </Box>
    </Form>
  );
};

export default Modal;
