import { Reducer } from 'redux';

import produce from 'immer';

import { Actions } from './actions';
import { IClientReducer } from './types';

const INITIAL_STATE: IClientReducer = {
  loading: false,
  loadingDetail: false,
  clientDetail: undefined,
};

type Client = Reducer<IClientReducer>;

const clients: Client = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Actions.GET_CLIENTS_REQUEST: {
        draft.loading = true;
        break;
      }

      case Actions.GET_CLIENTS_SUCCESS: {
        draft.loading = false;
        draft.data = action.payload;
        break;
      }

      case Actions.GET_CLIENTS_FAILURE: {
        draft.loading = false;
        break;
      }

      case Actions.GET_DETAIL_CLIENTS_REQUEST: {
        draft.loadingDetail = true;
        break;
      }

      case Actions.GET_DETAIL_CLIENTS_SUCCESS: {
        draft.loadingDetail = false;
        draft.clientDetail = action.payload;
        break;
      }

      case Actions.GET_DETAIL_CLIENTS_FAILURE: {
        draft.loadingDetail = false;
        break;
      }

      case Actions.CREATE_CLIENTS_REQUEST: {
        draft.loadingDetail = true;
        break;
      }

      case Actions.CREATE_CLIENTS_SUCCESS: {
        draft.loadingDetail = false;
        break;
      }

      case Actions.CREATE_CLIENTS_FAILURE: {
        draft.loadingDetail = false;
        break;
      }

      case Actions.DELETE_CLIENTS_REQUEST: {
        draft.loading = true;
        break;
      }

      case Actions.DELETE_CLIENTS_SUCCESS: {
        draft.loading = false;
        break;
      }

      case Actions.DELETE_CLIENTS_FAILURE: {
        draft.loading = false;
        break;
      }

      case Actions.UPDATE_CLIENTS_REQUEST: {
        draft.loadingDetail = true;
        break;
      }

      case Actions.UPDATE_CLIENTS_SUCCESS: {
        draft.loadingDetail = false;
        break;
      }

      case Actions.UPDATE_CLIENTS_FAILURE: {
        draft.loadingDetail = false;
        break;
      }

      case Actions.CLEAR_DETAIL_CLIENTS: {
        draft.clientDetail = undefined;
        break;
      }

      default:
    }
  });
};

export default clients;
