import React, { useEffect, useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Heading, Box } from '@chakra-ui/react';

import AlertDialog from '~/components/AlertDialog';

import {
  clearClientsDetail,
  createClientsRequest,
  deleteClientsRequest,
  getClientsDetailRequest,
  getClientsRequest,
  updateClientsRequest,
} from '~/store/modules/clients/actions';
import { openDrawer, openModal } from '~/store/modules/global/actions';

import DrawerContent from './components/DrawerContent';
import Filters from './components/Filters';
import ModalContent from './components/ModalContent';
import Table from './components/Table';
import { useClient } from './context';
import { Container } from './styles';

const Clients: React.FC = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState('');
  const dispatch = useDispatch();

  const { setFormData } = useClient();

  useEffect(() => {
    dispatch(getClientsRequest({}));
  }, [dispatch]);

  const handleSubmitData = useCallback(
    data => {
      dispatch(createClientsRequest(data));
    },
    [dispatch],
  );

  const handleSubmitDataEdit = useCallback(
    data => {
      dispatch(updateClientsRequest(data));
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    data => {
      setFormData(data);
      dispatch(
        openModal({
          open: true,
          title: 'Editar cliente',
          content: (
            <ModalContent type="edit" handleSendData={handleSubmitDataEdit} />
          ),
        }),
      );
    },
    [dispatch, handleSubmitDataEdit, setFormData],
  );

  const handleDelete = useCallback(() => {
    dispatch(deleteClientsRequest({ id }));
  }, [dispatch, id]);

  const handleDetail = useCallback(
    data => {
      setFormData(data);
      dispatch(clearClientsDetail());

      dispatch(getClientsDetailRequest({ id: data.id }));

      setTimeout(() => {
        dispatch(
          openDrawer({
            open: true,
            title: 'Detalhes do clientes',
            content: {
              main: <DrawerContent data={data} />,
            },
          }),
        );
      }, 1000);
    },
    [dispatch, setFormData],
  );

  return (
    <Container>
      <Heading as="h2" mb="3">
        Clientes
      </Heading>
      <Filters handleSubmitData={handleSubmitData} />

      <Box
        p="2"
        mt="5"
        w="100%"
        minH="100px"
        rounded="md"
        pos="relative"
        boxShadow="sm"
        overflow="hidden"
        backgroundColor="white"
      >
        <Table
          onDelete={guid => {
            setId(guid);
            setDeleteModal(true);
          }}
          onEdit={handleEdit}
          onDetail={handleDetail}
        />
      </Box>
      <AlertDialog
        title="Deletar cliente"
        open={deleteModal}
        onCancel={() => {
          setDeleteModal(false);
          setId('');
        }}
        onConfirm={() => handleDelete()}
      />
    </Container>
  );
};

export default Clients;
