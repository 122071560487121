import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  height: 100%;
  max-height: 70px;
  grid-area: header;
  position: fixed;

  background: white;
  box-shadow: 0px -1px 2px 1px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    max-width: calc(240px - 50px);
    width: 100%;
  }

  img {
    width: 170px;
  }
`;
