import { Action } from 'redux';

import { OpenModalProps, OpenDrawerProps } from './types';

export const Actions = {
  OPEN_MODAL: '@global/OPEN_MODAL',
  OPEN_DRAWER: '@global/OPEN_DRAWER',
  LOADING: '@globals/LOADING',
};

export function openModal(data: OpenModalProps): Action {
  return {
    type: Actions.OPEN_MODAL,
    payload: data,
  };
}

export function openDrawer(data: OpenDrawerProps): Action {
  return {
    type: Actions.OPEN_DRAWER,
    payload: data,
  };
}

export function setLoading(loading: boolean): Action {
  return {
    type: Actions.LOADING,
    payload: loading,
  };
}
