import { Action } from 'redux';

import { SellerRequest, SellerData, FormData } from './types';

export const Actions = {
  GET_SELLERS_REQUEST: 'sellers/GET_REQUEST',
  GET_SELLERS_SUCCESS: 'sellers/GET_SUCCESS',
  GET_SELLERS_FAILURE: 'sellers/GET_FAILURE',

  CREATE_SELLERS_REQUEST: 'sellers/CREATE_REQUEST',
  CREATE_SELLERS_SUCCESS: '@sellers/CREATE_SUCCESS',
  CREATE_SELLERS_FAILURE: '@sellers/CREATE_FAILURE',

  UPDATE_SELLERS_REQUEST: 'sellers/UPDATE_REQUEST',
  UPDATE_SELLERS_SUCCESS: '@sellers/UPDATE_SUCCESS',
  UPDATE_SELLERS_FAILURE: '@sellers/UPDATE_FAILURE',

  DELETE_SELLERS_REQUEST: 'sellers/DELETE_REQUEST',
  DELETE_SELLERS_SUCCESS: 'sellers/DELETE_SUCCESS',
  DELETE_SELLERS_FAILURE: 'sellers/DELETE_FAILURE',
};

/*
  ?Busca lista de vendedoras
*/
export function getSellersRequest(data: SellerRequest): Action {
  return {
    type: Actions.GET_SELLERS_REQUEST,
    payload: data,
  };
}

export function getSellersSuccess(data?: SellerData[]): Action {
  return {
    type: Actions.GET_SELLERS_SUCCESS,
    payload: data,
  };
}

export function getSellersFailure(): Action {
  return {
    type: Actions.GET_SELLERS_FAILURE,
  };
}

/*
  ?Deleta vendedora por id
*/

export function deleteSellersRequest(data: { id: number }): Action {
  return {
    type: Actions.DELETE_SELLERS_REQUEST,
    payload: data,
  };
}

export function deleteSellersSuccess(): Action {
  return {
    type: Actions.GET_SELLERS_SUCCESS,
  };
}

export function deleteSellersFailure(): Action {
  return {
    type: Actions.GET_SELLERS_FAILURE,
  };
}

/*
  ?Cria uma vendedora
*/
export function createSellersRequest(data: FormData): Action {
  return {
    type: Actions.CREATE_SELLERS_REQUEST,
    payload: data,
  };
}

export function createSellersSuccess(): Action {
  return {
    type: Actions.CREATE_SELLERS_SUCCESS,
  };
}

export function createSellersFailure(): Action {
  return {
    type: Actions.CREATE_SELLERS_FAILURE,
  };
}

/*
  ?Edita uma vendedora
*/
export function updateSellersRequest(data: SellerData): Action {
  return {
    type: Actions.UPDATE_SELLERS_REQUEST,
    payload: data,
  };
}

export function updateSellersSuccess(): Action {
  return {
    type: Actions.UPDATE_SELLERS_SUCCESS,
  };
}

export function updateSellersFailure(): Action {
  return {
    type: Actions.UPDATE_SELLERS_FAILURE,
  };
}
