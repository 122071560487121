import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  Modal as ModalChakra,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { openModal as actionOpenModal } from '~/store/modules/global/actions';

import Loading from '../Loading';

const Modal: React.FC = () => {
  const dispatch = useDispatch();

  const { loading, modal } = useSelector((state: State) => state.globals);

  const handleModalClosed = useCallback(() => {
    dispatch(actionOpenModal({ open: false, title: '', content: null }));
  }, [dispatch]);

  return (
    <ModalChakra isOpen={modal.open} onClose={handleModalClosed}>
      <ModalOverlay />
      <ModalContent pos="relative">
        <Loading loading={loading} />
        <ModalHeader>{modal.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{modal.content}</ModalBody>
      </ModalContent>
    </ModalChakra>
  );
};

export default Modal;
