import React, { useState, createContext, useContext, useCallback } from 'react';

import { EventFilters, EventsContextData, FormData } from './types';

const EventsContext = createContext({} as EventsContextData);

const EventProvider: React.FC = ({ children }) => {
  const [filters, setFilterValues] = useState({} as EventFilters);
  const [formData, setFormData] = useState({} as FormData);

  const setFilters = useCallback(
    data => {
      setFilterValues({ ...filters, ...data });
    },
    [filters],
  );

  return (
    <EventsContext.Provider
      value={{
        filters,
        setFilters,
        formData,
        setFormData,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

function useEvent(): EventsContextData {
  const context = useContext(EventsContext);

  if (!context) {
    throw new Error('É obrigatório o usuário do Provider');
  }

  return context;
}

export { EventProvider, useEvent };
