import { Action } from 'redux';

import { EventRequest, EventData, FormData } from './types';

export const Actions = {
  GET_EVENTS_REQUEST: '@events/GET_REQUEST',
  GET_EVENTS_SUCCESS: '@events/GET_SUCCESS',
  GET_EVENTS_FAILURE: '@events/GET_FAILURE',

  CREATE_EVENTS_REQUEST: '@events/CREATE_REQUEST',
  CREATE_EVENTS_SUCCESS: '@events/CREATE_SUCCESS',
  CREATE_EVENTS_FAILURE: '@events/CREATE_FAILURE',

  UPDATE_EVENTS_REQUEST: '@events/UPDATE_REQUEST',
  UPDATE_EVENTS_SUCCESS: '@events/UPDATE_SUCCESS',
  UPDATE_EVENTS_FAILURE: '@events/UPDATE_FAILURE',

  DELETE_EVENTS_REQUEST: '@events/DELETE_REQUEST',
  DELETE_EVENTS_SUCCESS: '@events/DELETE_SUCCESS',
  DELETE_EVENTS_FAILURE: '@events/DELETE_FAILURE',
};

/*
  ?Busca lista de eventos
*/
export function getEventsRequest(data: EventRequest): Action {
  return {
    type: Actions.GET_EVENTS_REQUEST,
    payload: data,
  };
}

export function getEventsSuccess(data?: EventData[]): Action {
  return {
    type: Actions.GET_EVENTS_SUCCESS,
    payload: data,
  };
}

export function getEventsFailure(): Action {
  return {
    type: Actions.GET_EVENTS_FAILURE,
  };
}

/*
  ?Deleta evento por id
*/

export function deleteEventsRequest(data: { id: string }): Action {
  return {
    type: Actions.DELETE_EVENTS_REQUEST,
    payload: data,
  };
}

export function deleteEventsSuccess(): Action {
  return {
    type: Actions.GET_EVENTS_SUCCESS,
  };
}

export function deleteEventsFailure(): Action {
  return {
    type: Actions.GET_EVENTS_FAILURE,
  };
}

/*
  ?Cria um evento
*/
export function createEventsRequest(data: FormData): Action {
  return {
    type: Actions.CREATE_EVENTS_REQUEST,
    payload: data,
  };
}

export function createEventsSuccess(): Action {
  return {
    type: Actions.CREATE_EVENTS_SUCCESS,
  };
}

export function createEventsFailure(): Action {
  return {
    type: Actions.CREATE_EVENTS_FAILURE,
  };
}

/*
  ?Edita um evento
*/
export function updateEventsRequest(data: EventData): Action {
  return {
    type: Actions.UPDATE_EVENTS_REQUEST,
    payload: data,
  };
}

export function updateEventsSuccess(): Action {
  return {
    type: Actions.UPDATE_EVENTS_SUCCESS,
  };
}

export function updateEventsFailure(): Action {
  return {
    type: Actions.UPDATE_EVENTS_FAILURE,
  };
}
