import * as Yup from 'yup';

const schema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  password: Yup.string()
    .min(5, 'Mínimo de 5 carácteres')
    .required('Senha obrigatória'),
});

export default schema;
