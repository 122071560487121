import React, { useState, createContext, useContext, useCallback } from 'react';

import { ClientFilters, ClientsContextData, FormData } from './types';

const ClientsContext = createContext({} as ClientsContextData);

const ClientProvider: React.FC = ({ children }) => {
  const [filters, setFilterValues] = useState({} as ClientFilters);
  const [formData, setFormData] = useState({} as FormData);

  const setFilters = useCallback(
    data => {
      setFilterValues({ ...filters, ...data });
    },
    [filters],
  );

  return (
    <ClientsContext.Provider
      value={{
        filters,
        setFilters,
        formData,
        setFormData,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

function useClient(): ClientsContextData {
  const context = useContext(ClientsContext);

  if (!context) {
    throw new Error('É obrigatório o usuário do Provider');
  }

  return context;
}

export { ClientProvider, useClient };
