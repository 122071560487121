import { all, call, put, takeLatest, Payload } from 'redux-saga/effects';

import { ApiResponse } from 'apisauce';
import _ from 'lodash';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { setLoading, openModal } from '~/store/modules/global/actions';

import {
  Actions,
  deleteClientsFailure,
  deleteClientsSuccess,
  getClientsRequest,
  getClientsFailure,
  getClientsSuccess,
  createClientsFailure,
  createClientsSuccess,
  updateClientsFailure,
  updateClientsSuccess,
  getClientsDetailFailure,
  getClientsDetailSuccess,
} from './actions';
import { ClientRequest, ClientData, FormData, ClientDetail } from './types';

//! Busca os clientes
type GetClientPayload = Payload<ClientRequest>;
export function* getClients({ payload }: GetClientPayload): Generator {
  const { filters } = payload;

  const response = yield call(() => {
    return api.get('clients', filters);
  });

  const { data, ok } = response as ApiResponse<ClientData[]>;

  if (!ok) {
    toast.error('Erro ao buscar clientes cadastrados!');

    return yield put(getClientsFailure());
  }

  const prepareData = data?.map(d => {
    if (d.adjustment?.length) {
      return {
        ...d,
        status: {
          name: 'Ajuste Marcado',
          type: 'yellow',
        },
      };
    }

    if (!d.rentals?.length) {
      return {
        ...d,
        status: {
          name: 'Nenhum status',
          type: 'gray',
        },
      };
    }

    const findDelivery = d.rentals.find(e => e.delivered_at);

    if (!findDelivery) {
      return {
        ...d,
        status: {
          name: `Produto reservado`,
          type: 'red',
        },
      };
    }

    return {
      ...d,
      status: {
        name: `Produto entregue`,
        type: 'green',
      },
    };
  });

  return yield put(getClientsSuccess(prepareData as ClientData[]));
}

export function* getDetails({ payload }: Payload<{ id: string }>): Generator {
  const response = yield call(() => {
    return api.get(`clients/${payload.id}`);
  });

  const { ok, data } = response as ApiResponse<ClientDetail>;

  if (!ok) {
    toast.error('Erro ao busca detalhes do cliente');

    return yield put(getClientsDetailFailure());
  }

  return yield put(getClientsDetailSuccess(data));
}

//! Cria um  cliente
type CreateClientPayload = Payload<FormData>;
export function* createClient({ payload }: CreateClientPayload): Generator {
  yield put(setLoading(true));

  const response = yield call(() => {
    return api.post('clients', payload);
  });

  const { ok } = response as ApiResponse<ClientData[]>;

  yield put(setLoading(false));
  if (!ok) {
    toast.error('Erro ao cadastrar cliente!');

    return yield put(createClientsFailure());
  }

  yield put(openModal({ content: null, open: false, title: '' }));

  yield put(getClientsRequest({}));

  return yield put(createClientsSuccess());
}

//! Edita um  cliente
type EditClientPayload = Payload<ClientData>;
export function* editClient({ payload }: EditClientPayload): Generator {
  yield put(setLoading(true));

  const { id } = payload;

  const response = yield call(() => {
    return api.put(`clients/${id}`, payload);
  });

  const { ok } = response as ApiResponse<ClientData>;

  yield put(setLoading(false));

  if (!ok) {
    toast.error('Erro ao editar cliente!');

    return yield put(updateClientsFailure());
  }

  yield put(openModal({ content: null, open: false, title: '' }));

  yield put(getClientsRequest({}));

  return yield put(updateClientsSuccess());
}

// !Deleta um cliente
type DelClientPayload = Payload<{ id: number }>;
export function* deleteClient({ payload }: DelClientPayload): Generator {
  const { id } = payload;

  const response = yield call(() => {
    return api.delete(`clients/${id}`);
  });

  const { ok } = response as ApiResponse<ClientData[]>;

  if (!ok) {
    toast.error('Erro ao excluir cliente!');

    return yield put(deleteClientsFailure());
  }
  toast.success('Cliente excluído com sucesso!');

  yield put(getClientsRequest({}));

  return yield put(deleteClientsSuccess());
}

export default all([
  takeLatest(Actions.GET_CLIENTS_REQUEST, getClients),
  takeLatest(Actions.GET_DETAIL_CLIENTS_REQUEST, getDetails),
  takeLatest(Actions.CREATE_CLIENTS_REQUEST, createClient),
  takeLatest(Actions.UPDATE_CLIENTS_REQUEST, editClient),
  takeLatest(Actions.DELETE_CLIENTS_REQUEST, deleteClient),
]);
