import React, { useEffect, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { Heading, Box } from '@chakra-ui/react';

import { openDrawer, openModal } from '~/store/modules/global/actions';
import {
  createSellersRequest,
  deleteSellersRequest,
  getSellersRequest,
  updateSellersRequest,
} from '~/store/modules/sellers/actions';

import DrawerContent from './components/DrawerContent';
import Filters from './components/Filters';
import ModalContent from './components/ModalContent';
import Table from './components/Table';
import { useSeller } from './context';
import { Container } from './styles';

const Clients: React.FC = () => {
  const dispatch = useDispatch();

  const { setFormData } = useSeller();

  useEffect(() => {
    dispatch(getSellersRequest({}));
  }, [dispatch]);

  const handleSubmitData = useCallback(
    data => {
      dispatch(createSellersRequest(data));
    },
    [dispatch],
  );

  const handleSubmitDataEdit = useCallback(
    data => {
      dispatch(updateSellersRequest(data));
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    data => {
      setFormData(data);
      dispatch(
        openModal({
          open: true,
          title: 'Editar vendedora',
          content: (
            <ModalContent type="edit" handleSendData={handleSubmitDataEdit} />
          ),
        }),
      );
    },
    [dispatch, handleSubmitDataEdit, setFormData],
  );

  const handleDelete = useCallback(
    id => {
      dispatch(deleteSellersRequest({ id }));
    },
    [dispatch],
  );

  const handleDetail = useCallback(
    data => {
      setFormData(data);
      dispatch(
        openDrawer({
          open: true,
          title: 'Detalhes da vendedora',
          content: {
            main: <DrawerContent />,
          },
        }),
      );
    },
    [dispatch, setFormData],
  );

  return (
    <Container>
      <Heading as="h2" mb="3">
        Vendedoras
      </Heading>
      <Filters handleSubmitData={handleSubmitData} />

      <Box
        p="2"
        mt="5"
        w="100%"
        minH="100px"
        rounded="md"
        pos="relative"
        boxShadow="sm"
        overflow="hidden"
        backgroundColor="white"
      >
        <Table
          onDelete={handleDelete}
          onEdit={handleEdit}
          onDetail={handleDetail}
        />
      </Box>
    </Container>
  );
};

export default Clients;
