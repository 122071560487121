import React, { useCallback, useRef, useEffect } from 'react';

import { Stack, Box, Button } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { ValidationError } from 'yup';

import { Input, DatePicker } from '~/components/Form';

import getValidationErrors from '~/utils/getValidationErrors';
import eventValidator from '~/validators/events';

import { useEvent } from '../../context';
import { FormData } from '../../context/types';

interface ModalProps {
  type: 'new' | 'edit';
  handleSendData: (data: FormData) => void;
}

const Modal: React.FC<ModalProps> = ({ handleSendData, type }) => {
  const formRef = useRef<FormHandles>(null);

  const { formData, setFormData } = useEvent();

  useEffect(() => {
    if (type === 'new') return;

    formRef?.current?.setData(formData);
  }, [formData, setFormData, type]);

  const handleSubmit = useCallback(
    async data => {
      formRef?.current?.setErrors({});

      try {
        await eventValidator.validate(data, {
          abortEarly: false,
        });

        handleSendData(data);
      } catch (errors) {
        if (errors instanceof ValidationError) {
          const sanitizeErrors = getValidationErrors(errors);

          formRef?.current?.setErrors(sanitizeErrors);
        }
      }
    },
    [handleSendData],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {type === 'edit' && <Input name="id" type="hidden" />}
        <Input name="name" placeholder="Nome do evento" />
        <Input name="place" placeholder="Local" />
        <DatePicker name="date" placeholderText="Data" />
        <Input name="description" placeholder="Descrição" />
      </Stack>

      <Box w="50%" ml="auto" mt={5} mb={5}>
        <Button type="submit" float="right" mb="3" colorScheme="orange">
          {type === 'edit' ? 'Editar' : 'Cadastrar'}
        </Button>
      </Box>
    </Form>
  );
};

export default Modal;
