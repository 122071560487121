import React from 'react';

import { useSelector } from 'react-redux';

import { Avatar, Box, Text } from '@chakra-ui/react';

import logo from '~/assets/logo.png';

import { Container } from './styles';

const Header: React.FC = () => {
  const { data } = useSelector((state: State) => state.user);

  return (
    <Container>
      <div className="logo">
        <img src={logo} alt="Dress me Up" />
      </div>

      <Box d="flex" alignItems="center">
        <Text>Olá {data?.name.split(' ')[0] || ''}</Text>
        <Avatar
          ml="3"
          size="md"
          src={`${process.env.REACT_APP_API_URL}/avatars/${data?.userEmail}`}
          name={data?.name || ''}
          backgroundColor="gray.200"
        />
      </Box>
    </Container>
  );
};

export default Header;
