import React from 'react';

import { Switch, HashRouter } from 'react-router-dom';

import { ConnectedRouter } from 'connected-react-router';

import Clients from '~/pages/Clients';
import Events from '~/pages/Events';
import Home from '~/pages/Home';
import Sellers from '~/pages/Sellers';
import SignIn from '~/pages/SignIn';
import history from '~/services/history';

import Route from './Route';

const Routes: React.FC = () => {
  return (
    <ConnectedRouter history={history}>
      <HashRouter basename="/" hashType="slash">
        <Switch>
          <Route path="/" exact component={SignIn} />

          <Route path="/dashboard" component={Home} isPrivate />
          <Route path="/clients" component={Clients} isPrivate />
          <Route path="/events" component={Events} isPrivate />
          <Route path="/sellers" component={Sellers} isPrivate />
        </Switch>
      </HashRouter>
    </ConnectedRouter>
  );
};

export default Routes;
