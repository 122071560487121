import { GiPerson } from 'react-icons/gi';
import { MdHome, MdPerson, MdEventAvailable } from 'react-icons/md';

const menu = [
  {
    name: 'Home',
    link: '/dashboard',
    icon: MdHome,
  },
  {
    name: 'Clientes',
    link: '/clients',
    icon: MdPerson,
  },
  {
    name: 'Eventos',
    link: '/events',
    icon: MdEventAvailable,
  },
  {
    name: 'Vendedoras',
    link: '/sellers',
    icon: GiPerson,
  },
];

export default menu;
