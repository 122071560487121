import React, { useMemo } from 'react';

import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import _ from 'lodash';

import menu from './menu';
import { Container } from './styles';

const SideMenu: React.FC = () => {
  const { pathname } = useLocation();

  useMemo(() => {
    const title = _.find(menu, e => e.link === pathname);

    document.title = `${title?.name} | Dress me up`;
  }, [pathname]);

  return (
    <Container>
      {menu.map(({ icon: Icon, link, name }, id) => (
        <Link
          to={link}
          key={String(id)}
          className={classNames({
            active: pathname === link,
          })}
        >
          <Icon color="white" size={30} />
          {name}
        </Link>
      ))}
    </Container>
  );
};

export default SideMenu;
