import React, { useCallback, useRef, useEffect } from 'react';

import { Stack, SimpleGrid, Box, Button } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { ValidationError } from 'yup';

import { Input } from '~/components/Form';

import getValidationErrors from '~/utils/getValidationErrors';
import sellerValidator from '~/validators/sellers';

import { useSeller } from '../../context';
import { FormData } from '../../context/types';

interface ModalProps {
  type: 'new' | 'edit';
  handleSendData: (data: FormData) => void;
}

const Modal: React.FC<ModalProps> = ({ handleSendData, type }) => {
  const formRef = useRef<FormHandles>(null);

  const { formData, setFormData } = useSeller();

  useEffect(() => {
    if (type === 'new') return;

    formRef?.current?.setData(formData);
  }, [formData, setFormData, type]);

  const handleSubmit = useCallback(
    async data => {
      formRef?.current?.setErrors({});

      try {
        if (type === 'new') {
          await sellerValidator.validate(data, {
            abortEarly: false,
          });
        }

        handleSendData(data);
      } catch (errors) {
        if (errors instanceof ValidationError) {
          const sanitizeErrors = getValidationErrors(errors);

          formRef?.current?.setErrors(sanitizeErrors);
        }
      }
    },
    [handleSendData, type],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {type === 'edit' && <Input name="id" type="hidden" />}
        <Input name="name" placeholder="Nome completo" />
        <Input name="document" placeholder="CPF" />
        <Input name="email" placeholder="E-mail" />
        <SimpleGrid columns={2} spacing={2}>
          {type === 'edit' && (
            <Input
              name="oldPassword"
              type="password"
              placeholder="Senha antiga"
            />
          )}
          <Input
            name="password"
            type="password"
            placeholder={type === 'new' ? 'Senha' : 'Nova senha'}
          />
          {type === 'new' && (
            <Input
              name="confirmPassword"
              type="password"
              placeholder="Confirmar senha"
            />
          )}
        </SimpleGrid>
      </Stack>

      <Box w="50%" ml="auto" mt={5} mb={5}>
        <Button type="submit" float="right" mb="3" colorScheme="orange">
          {type === 'new' ? 'Cadastrar' : 'Editar'}
        </Button>
      </Box>
    </Form>
  );
};

export default Modal;
