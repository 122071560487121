import React from 'react';

import { useSelector } from 'react-redux';

import {
  Table as TableChakra,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Badge,
} from '@chakra-ui/react';
// import { NotePencil, Trash } from 'phosphor-react';

import _ from 'lodash';

import Loading from '~/components/Loading';

import currency from '~/utils/currency';

import { SellerData } from '../../context/types';

interface TableProps {
  onDelete: (id: number) => void;
  onEdit: (data: SellerData) => void;
  onDetail: (data: SellerData) => void;
}

interface SellerPropsValues {
  sales: {
    discount: number;
    additional_value: number;
    product: { price: number };
  }[];
}

const Table: React.FC<TableProps> = ({ onDetail }) => {
  const sellers = useSelector((state: State) => state.sellers);

  const calculation = (data: SellerPropsValues): string => {
    const sumDiscount = _.sumBy(data.sales, 'discount').toFixed(1);
    const sumPrice = _.sumBy(data.sales, e => e.product.price).toFixed(1);

    const prepareValue =
      (parseFloat(String(sumPrice)) - parseFloat(String(sumDiscount))) * 0.01;

    return currency(String(prepareValue));
  };

  const salesTotal = (data: SellerPropsValues): React.ReactNode => {
    const sumSales = data.sales.length;
    // const sumSales = Math.floor(Math.random() * Math.floor(30));

    if (sumSales === 0) {
      return <Badge colorScheme="red">Nenhuma venda</Badge>;
    }

    if (sumSales <= 5) {
      return <Badge colorScheme="yellow">{sumSales} vendas</Badge>;
    }

    if (sumSales <= 15) {
      return <Badge colorScheme="teal">{sumSales} vendas</Badge>;
    }

    return <Badge colorScheme="green">{sumSales} vendas</Badge>;
  };

  return (
    <>
      <Loading loading={sellers.loading} />
      <TableChakra variant="simple">
        <TableCaption>Imperial to metric conversion factors</TableCaption>
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>E-mail</Th>
            <Th>CPF</Th>
            <Th>Vendas no mês</Th>
            <Th isNumeric>Comissão mensal</Th>
            {/* <Th>&nbsp;</Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {sellers?.data?.map(seller => (
            <Tr key={String(seller.id)} _hover={{ backgroundColor: '#f8fdff' }}>
              <Td cursor="pointer" onClick={() => onDetail(seller)}>
                {seller.name}
              </Td>
              <Td>{seller.email}</Td>
              <Td>{seller.document}</Td>
              <Td>{salesTotal((seller as unknown) as SellerPropsValues)}</Td>
              <Td isNumeric>
                {calculation((seller as unknown) as SellerPropsValues)}
              </Td>

              {/* <Td isNumeric>
                <Button
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => onEdit(seller)}
                >
                  <Box as={NotePencil} color="teal" size={20} />
                </Button>
                <Button
                  colorScheme="red"
                  variant="ghost"
                  onClick={() => onDelete(seller.id)}
                >
                  <Box as={Trash} color="red" size={20} />
                </Button>
              </Td> */}
            </Tr>
          ))}
        </Tbody>
      </TableChakra>
    </>
  );
};

export default Table;
