import { Action } from 'redux';

import { ClientRequest, ClientData, FormData, ClientDetail } from './types';

export const Actions = {
  GET_CLIENTS_REQUEST: '@clients/GET_REQUEST',
  GET_CLIENTS_SUCCESS: '@clients/GET_SUCCESS',
  GET_CLIENTS_FAILURE: '@clients/GET_FAILURE',

  GET_DETAIL_CLIENTS_REQUEST: '@clients/GET_DETAIL_REQUEST',
  GET_DETAIL_CLIENTS_SUCCESS: '@clients/GET_DETAIL_SUCCESS',
  GET_DETAIL_CLIENTS_FAILURE: '@clients/GET_DETAIL_FAILURE',

  CREATE_CLIENTS_REQUEST: '@clients/CREATE_REQUEST',
  CREATE_CLIENTS_SUCCESS: '@clients/CREATE_SUCCESS',
  CREATE_CLIENTS_FAILURE: '@clients/CREATE_FAILURE',

  UPDATE_CLIENTS_REQUEST: '@clients/UPDATE_REQUEST',
  UPDATE_CLIENTS_SUCCESS: '@clients/UPDATE_SUCCESS',
  UPDATE_CLIENTS_FAILURE: '@clients/UPDATE_FAILURE',

  DELETE_CLIENTS_REQUEST: '@clients/DELETE_REQUEST',
  DELETE_CLIENTS_SUCCESS: '@clients/DELETE_SUCCESS',
  DELETE_CLIENTS_FAILURE: '@clients/DELETE_FAILURE',

  CLEAR_DETAIL_CLIENTS: '@clients/CLEAR_DETAIL_CLIENTS',
};

/*
  ?Busca lista de clientes
*/
export function getClientsRequest(data: ClientRequest): Action {
  return {
    type: Actions.GET_CLIENTS_REQUEST,
    payload: data,
  };
}

export function getClientsSuccess(data?: ClientData[]): Action {
  return {
    type: Actions.GET_CLIENTS_SUCCESS,
    payload: data,
  };
}

export function getClientsFailure(): Action {
  return {
    type: Actions.GET_CLIENTS_FAILURE,
  };
}

/*
  ?Busca detalhamento de cliente
*/

export function clearClientsDetail(): Action {
  return {
    type: Actions.CLEAR_DETAIL_CLIENTS,
  };
}

export function getClientsDetailRequest(data: { id: string }): Action {
  return {
    type: Actions.GET_DETAIL_CLIENTS_REQUEST,
    payload: data,
  };
}

export function getClientsDetailSuccess(data?: ClientDetail): Action {
  return {
    type: Actions.GET_DETAIL_CLIENTS_SUCCESS,
    payload: data,
  };
}

export function getClientsDetailFailure(): Action {
  return {
    type: Actions.GET_DETAIL_CLIENTS_FAILURE,
  };
}

/*
  ?Deleta cliente por id
*/

export function deleteClientsRequest(data: { id: string }): Action {
  return {
    type: Actions.DELETE_CLIENTS_REQUEST,
    payload: data,
  };
}

export function deleteClientsSuccess(): Action {
  return {
    type: Actions.GET_CLIENTS_SUCCESS,
  };
}

export function deleteClientsFailure(): Action {
  return {
    type: Actions.GET_CLIENTS_FAILURE,
  };
}

/*
  ?Cria um cliente
*/
export function createClientsRequest(data: FormData): Action {
  return {
    type: Actions.CREATE_CLIENTS_REQUEST,
    payload: data,
  };
}

export function createClientsSuccess(): Action {
  return {
    type: Actions.CREATE_CLIENTS_SUCCESS,
  };
}

export function createClientsFailure(): Action {
  return {
    type: Actions.CREATE_CLIENTS_FAILURE,
  };
}

/*
  ?Edita um cliente
*/
export function updateClientsRequest(data: ClientData): Action {
  return {
    type: Actions.UPDATE_CLIENTS_REQUEST,
    payload: data,
  };
}

export function updateClientsSuccess(): Action {
  return {
    type: Actions.UPDATE_CLIENTS_SUCCESS,
  };
}

export function updateClientsFailure(): Action {
  return {
    type: Actions.UPDATE_CLIENTS_FAILURE,
  };
}
