import { all, takeLatest, call, put, Payload } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import api, { ApiResponse } from '~/services/api';
import history from '~/services/history';

import { signInFailure, signInSuccess } from './actions';
import { Actions } from './actions';
import { ISignInRequest, ISignInSuccess } from './types';

type SignInPayload = Payload<ISignInRequest>;

export function* signIn({ payload }: SignInPayload): Generator {
  const response = yield call(() => {
    return api.post('session', payload);
  });

  const { data, ok } = response as ApiResponse<ISignInSuccess>;

  if (!ok) {
    toast.error('Ocorreu um erro, verifique seus dados e tente novamente!');

    return yield put(signInFailure());
  }

  api.setHeaders({ Authorization: `Bearer ${data?.token}` });

  history.push('/dashboard');

  return yield put(signInSuccess(data as ISignInSuccess));
}

interface REHYDRATEProps {
  auth: {
    token: string;
  };
}

export function* checkKeepAndSetToken({
  payload,
}: Payload<REHYDRATEProps>): Generator {
  if (!payload) return;

  const { token } = payload.auth;

  api.setHeaders({ Authorization: `Bearer ${token}` });

  return yield true;
}

export default all([
  takeLatest(Actions.SIGN_IN_REQUEST, signIn),
  takeLatest(Actions.REHYDRATE, checkKeepAndSetToken),
]);
