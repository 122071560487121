import React, { useEffect, useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Heading, Box } from '@chakra-ui/react';
import { parseISO } from 'date-fns';

import AlertDialog from '~/components/AlertDialog';

import {
  createEventsRequest,
  deleteEventsRequest,
  getEventsRequest,
  updateEventsRequest,
} from '~/store/modules/events/actions';
import { openModal } from '~/store/modules/global/actions';

import Filters from './components/Filters';
import ModalContent from './components/ModalContent';
import Table from './components/Table';
import { useEvent } from './context';
import { Container } from './styles';

const Events: React.FC = () => {
  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState('');

  const { setFormData } = useEvent();

  useEffect(() => {
    dispatch(getEventsRequest({}));
  }, [dispatch]);

  const handleSubmitData = useCallback(
    data => {
      dispatch(createEventsRequest(data));
    },
    [dispatch],
  );

  const handleSubmitDataEdit = useCallback(
    data => {
      dispatch(updateEventsRequest(data));
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    data => {
      setFormData({
        ...data,
        date: parseISO(data.date),
      });
      dispatch(
        openModal({
          open: true,
          title: 'Editar evento',
          content: (
            <ModalContent type="edit" handleSendData={handleSubmitDataEdit} />
          ),
        }),
      );
    },
    [dispatch, handleSubmitDataEdit, setFormData],
  );

  const handleDelete = useCallback(() => {
    dispatch(deleteEventsRequest({ id }));
  }, [dispatch, id]);

  return (
    <Container>
      <Heading as="h2" mb="3">
        Eventos
      </Heading>
      <Filters handleSubmitData={handleSubmitData} />

      <Box
        p="2"
        mt="5"
        w="100%"
        minH="100px"
        rounded="md"
        pos="relative"
        boxShadow="sm"
        overflow="hidden"
        backgroundColor="white"
      >
        <Table onDelete={() => setDeleteModal(true)} onEdit={handleEdit} />
      </Box>
      <AlertDialog
        title="Deletar evento"
        open={deleteModal}
        onCancel={() => {
          setDeleteModal(false);
          setId('');
        }}
        onConfirm={() => handleDelete()}
      />
    </Container>
  );
};

export default Events;
