import React from 'react';

import {
  Box,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Badge,
} from '@chakra-ui/react';

import { useSeller } from '../../context';

const DrawerContent: React.FC = () => {
  const { formData } = useSeller();

  return (
    <Box mt="4">
      <Stat>
        <StatLabel>Nome da vendedora</StatLabel>
        <StatNumber>{formData.name}</StatNumber>

        <StatHelpText>
          <Badge colorScheme="teal">4 vendas este mes</Badge>
        </StatHelpText>
      </Stat>

      <Box mt="5">
        <Text>Produtos locados:</Text>
      </Box>
      <Box mt="3">
        <Text fontWeight="bold">Nenhum Produto locado</Text>
      </Box>
    </Box>
  );
};

export default DrawerContent;
