import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome Obrigatório'),
  password: Yup.string(),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password')],
    'Senhas não conferem',
  ),
});

export default schema;
