import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import clients from './clients/sagas';
import events from './events/sagas';
import sellers from './sellers/sagas';

export default function* rootSaga(): Generator {
  return yield all([auth, clients, events, sellers]);
}
