import { Reducer } from 'react';

import { AnyAction, CombinedState, combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import auth from './auth/reducer';
import clients from './clients/reducer';
import events from './events/reducer';
import globals from './global/reducer';
import sellers from './sellers/reducer';
import user from './user/reducer';

export default (history: History): Reducer<CombinedState<any>, AnyAction> => {
  return combineReducers({
    router: connectRouter(history),
    auth,
    user,
    clients,
    events,
    globals,
    sellers,
  });
};
