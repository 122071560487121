import React from 'react';

import { useSelector } from 'react-redux';

import {
  Table as TableChakra,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Box,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { NotePencil, Trash } from 'phosphor-react';

import Loading from '~/components/Loading';

import { EventData } from '../../context/types';

interface TableProps {
  onDelete: (id: number) => void;
  onEdit: (data: EventData) => void;
}

const Table: React.FC<TableProps> = ({ onDelete, onEdit }) => {
  const events = useSelector((state: State) => state.events);
  return (
    <>
      <Loading loading={events.loading} />
      <TableChakra variant="simple">
        <TableCaption>Imperial to metric conversion factors</TableCaption>
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Local</Th>
            <Th isNumeric>Data</Th>
            <Th>Descrição</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          {events?.data?.map(event => (
            <Tr key={String(event.id)} _hover={{ backgroundColor: '#f8fdff' }}>
              <Td>{event.name}</Td>
              <Td>{event.place}</Td>
              <Td isNumeric>
                {format(parseISO(event.date), "dd 'de' MMMM 'de' yyyy", {
                  locale: pt,
                })}
              </Td>
              <Td>{event.description}</Td>
              <Td isNumeric>
                <Button
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => onEdit(event)}
                >
                  <Box as={NotePencil} color="teal" size={20} />
                </Button>
                <Button
                  colorScheme="red"
                  variant="ghost"
                  onClick={() => onDelete(event.id)}
                >
                  <Box as={Trash} color="red" size={20} />
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </TableChakra>
    </>
  );
};

export default Table;
