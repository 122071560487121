import { Reducer } from 'redux';

import produce from 'immer';

import { Actions } from './actions';
import { OpenDrawerProps, OpenModalProps } from './types';

export interface IGlobalReducer {
  modal: OpenModalProps;
  drawer: OpenDrawerProps;
  loading: boolean;
}

const INITIAL_STATE: IGlobalReducer = {
  loading: false,
  modal: {
    open: false,
    title: '',
    content: null,
  },
  drawer: {
    content: null,
    open: false,
    title: '',
  },
};

type Global = Reducer<IGlobalReducer>;

const globals: Global = (state = INITIAL_STATE, actions) => {
  return produce(state, draft => {
    switch (actions.type) {
      case Actions.LOADING: {
        draft.loading = actions.payload;

        break;
      }

      case Actions.OPEN_MODAL: {
        draft.modal = actions.payload;

        break;
      }

      case Actions.OPEN_DRAWER: {
        draft.drawer = actions.payload;

        break;
      }

      default:
    }
  });
};

export default globals;
