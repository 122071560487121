import React from 'react';

import { useTheme, Theme } from '@chakra-ui/react';
import { BarLoader } from 'react-spinners';

interface LoadingProps {
  loading: boolean;
}

const Loading: React.FC<LoadingProps> = ({ loading }) => {
  const { colors } = useTheme<Theme>();

  return (
    <BarLoader
      css={`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      `}
      height="5px"
      loading={loading}
      color={colors.orange[500]}
    />
  );
};

export default Loading;
