import React from 'react';

import { useSelector } from 'react-redux';

import {
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Text,
  Badge,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  SimpleGrid,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import _ from 'lodash';

import Loading from '~/components/Loading';

import currency from '~/utils/currency';

import { ClientData } from '../../context/types';

interface DrawerProps {
  data: ClientData;
}

const DrawerContent: React.FC<DrawerProps> = ({ data }) => {
  const { loadingDetail, clientDetail } = useSelector(
    (state: State) => state.clients,
  );

  return (
    <Box mt="4">
      <Loading loading={loadingDetail} />
      <Stat>
        <StatLabel>Nome do cliente</StatLabel>
        <StatNumber>{data.name}</StatNumber>

        <StatHelpText>
          <Badge colorScheme={data.status?.type}>{data.status?.name}</Badge>
        </StatHelpText>
      </Stat>

      <SimpleGrid columns={2} spacing={3} mt="5">
        <Stat d="flex" justifyContent="flex-start" flexDir="column">
          <StatLabel fontSize="14px">Email</StatLabel>
          <StatNumber fontSize="15px">{clientDetail?.email}</StatNumber>
        </Stat>

        <Stat d="flex" justifyContent="flex-end" flexDir="column">
          <StatLabel fontSize="14px">Cliente desde:</StatLabel>
          <StatNumber fontSize="15px">
            {clientDetail &&
              format(
                parseISO(clientDetail?.createdAt || ''),
                "dd 'de' MMMM 'de' yyyy",
                { locale: pt },
              )}
          </StatNumber>
        </Stat>
      </SimpleGrid>
      {!loadingDetail && (
        <Box mt="5" pt="5">
          {!loadingDetail && !clientDetail?.rentals.length ? (
            <Text>Nenhum produto locado.</Text>
          ) : (
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Produto locado</Th>
                  <Th isNumeric>Valor do produto</Th>
                  <Th isNumeric>Total pago</Th>
                </Tr>
              </Thead>
              <Tbody>
                {clientDetail?.rentals.map(r => (
                  <Tr key={r.id}>
                    <Td>{r.product.name}</Td>
                    <Td isNumeric>{currency(String(r.product.price))}</Td>
                    <Td isNumeric>
                      {currency(String(_.sumBy(r.payments, 'value')))}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DrawerContent;
