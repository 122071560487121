import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

import { openDrawer } from '~/store/modules/global/actions';

import Loading from '../Loading';

const Drawer: React.FC = () => {
  const dispatch = useDispatch();

  const { loading, drawer } = useSelector((state: State) => state.globals);

  const handleDrawerClosed = useCallback(() => {
    dispatch(openDrawer({ content: null, open: false, title: '' }));
  }, [dispatch]);

  return (
    <ChakraDrawer
      isOpen={drawer.open}
      placement="right"
      onClose={handleDrawerClosed}
      size="md"
    >
      <DrawerOverlay>
        <DrawerContent pos="relative">
          <Loading loading={loading} />
          <DrawerCloseButton />
          <DrawerHeader borderColor="gray.300" borderBottomWidth="1px">
            {drawer.title}
          </DrawerHeader>

          <DrawerBody>{drawer.content?.main}</DrawerBody>

          {drawer.content?.footer && (
            <DrawerFooter borderColor="gray.300" borderTopWidth="1px">
              {drawer.content?.footer}
            </DrawerFooter>
          )}
        </DrawerContent>
      </DrawerOverlay>
    </ChakraDrawer>
  );
};

export default Drawer;
