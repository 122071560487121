import { Reducer } from 'redux';

import produce from 'immer';

import { Actions } from './actions';
import { ISellerReducer } from './types';

const INITIAL_STATE: ISellerReducer = {
  loading: false,
  loadingDetail: false,
};

type Seller = Reducer<ISellerReducer>;

const sellers: Seller = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Actions.GET_SELLERS_REQUEST: {
        draft.loading = true;
        break;
      }

      case Actions.GET_SELLERS_SUCCESS: {
        draft.loading = false;
        draft.data = action.payload;
        break;
      }

      case Actions.GET_SELLERS_FAILURE: {
        draft.loading = false;
        break;
      }

      case Actions.CREATE_SELLERS_REQUEST: {
        draft.loadingDetail = true;
        break;
      }

      case Actions.CREATE_SELLERS_SUCCESS: {
        draft.loadingDetail = false;
        break;
      }

      case Actions.CREATE_SELLERS_FAILURE: {
        draft.loadingDetail = false;
        break;
      }

      case Actions.DELETE_SELLERS_REQUEST: {
        draft.loading = true;
        break;
      }

      case Actions.DELETE_SELLERS_SUCCESS: {
        draft.loading = false;
        break;
      }

      case Actions.DELETE_SELLERS_FAILURE: {
        draft.loading = false;
        break;
      }

      case Actions.UPDATE_SELLERS_REQUEST: {
        draft.loadingDetail = true;
        break;
      }

      case Actions.UPDATE_SELLERS_SUCCESS: {
        draft.loadingDetail = false;
        break;
      }

      case Actions.UPDATE_SELLERS_FAILURE: {
        draft.loadingDetail = false;
        break;
      }

      default:
    }
  });
};

export default sellers;
