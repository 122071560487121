import React from 'react';

import { ClientProvider } from '~/pages/Clients/context';
import { EventProvider } from '~/pages/Events/context';
import { SellerProvider } from '~/pages/Sellers/context';

const Providers: React.FC = ({ children }) => {
  return (
    <ClientProvider>
      <EventProvider>
        <SellerProvider>{children}</SellerProvider>
      </EventProvider>
    </ClientProvider>
  );
};

export default Providers;
