import React, { useState, createContext, useContext, useCallback } from 'react';

import { SellerFilters, SellersContextData, FormData } from './types';

const SellersContext = createContext({} as SellersContextData);

const SellerProvider: React.FC = ({ children }) => {
  const [filters, setFilterValues] = useState({} as SellerFilters);
  const [formData, setFormData] = useState({} as FormData);

  const setFilters = useCallback(
    data => {
      setFilterValues({ ...filters, ...data });
    },
    [filters],
  );

  return (
    <SellersContext.Provider
      value={{
        filters,
        setFilters,
        formData,
        setFormData,
      }}
    >
      {children}
    </SellersContext.Provider>
  );
};

function useSeller(): SellersContextData {
  const context = useContext(SellersContext);

  if (!context) {
    throw new Error('É obrigatório o usuário do Provider');
  }

  return context;
}

export { SellerProvider, useSeller };
